import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                category: 'toggleCategory',
                crossNav: 'toggleCrossNav',
                crossInfowindow : 'toggleCrossInfowindow'
            },

            change: {
                subCategory: 'toggleCategory'
            }
        }

        this.map = false;
        this.categories = [];
    }

    init() {
        const el = this.$('map');
        setTimeout(() => {
            if (google !== undefined) {
                this.map = initMap($(el))
            }
        }, 2000);
        document.addEventListener("googlemaps_loaded", function() {
            setTimeout(() => {
                if (google !== undefined && this.map === false) {
                  this.map = initMap($(el));
                }
            }, 2000);
        });
    }

    toggleCategory(e) {
        let target = e.currentTarget;
        let markers = this.map.markers;
        let categories = this.categories;
        let id = '';

        if (e.type === 'change'){
            id = Number(target[target.options.selectedIndex].getAttribute('data-id'))
        } else{
            id = Number(target.getAttribute('data-id'))
        }

        if (categories.indexOf(id) != -1){
            categories.splice(categories.indexOf(id));
            target.classList.remove('is-active');
        } else{
            categories.push(id);
            target.classList.add('is-active')
        }

        markers.forEach(marker => {
            if(categories.length) {
                if(categories.indexOf(marker.category) != -1) {
                    marker.setVisible(true);
                }
                else {          
                    marker.setVisible(false);
                }
            }else{
                marker.setVisible(true);
            }
        })

        centerMap(this.map);
    }

    toggleCrossInfowindow(e){
        e.currentTarget.parentNode.parentNode.classList.add('c-map_infoWindows_close');
    }
}

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap( $el ) {
    // Find marker elements within map.
    var $markers = $el.find('.marker');

    // Create gerenic map.
    var mapArgs = {
        zoom        : $el.data('zoom') || 15,
        mapTypeId   : google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map( $el[0], mapArgs );

    // Add markers.
    map.markers = [];
    $markers.each(function(){
        initMarker( $(this), map );
    });

    // Center map based on markers.
    centerMap( map );

    // Return map instance.
    return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker( $marker, map ) {
    // Get position from marker.
    var lat = $marker.data('lat');
    var lng = $marker.data('lng');
    var latLng = {
        lat: parseFloat( lat ),
        lng: parseFloat( lng )
    };

    let image = '';
    if ($marker.data('category') == 9 || $marker.data('parent') == 9){
        image = '/app/uploads/2022/01/marqueur-loisirs.svg'
    } else if ($marker.data('category') == 6 || $marker.data('parent') == 6){
        image = '/app/uploads/2022/01/marqueur-services-publiques.svg'
    } else if ($marker.data('category') == 8 || $marker.data('parent') == 8){
        image = '/app/uploads/2022/01/marqueur-entreprise.svg'
    } else {
        image = '/app/uploads/2022/01/marqueur-neutre.svg'
    };

    // Create marker instance.
    var marker = new google.maps.Marker({
        position : latLng,
        map: map,
        category : $marker.data('category'),
        icon : {
            url : image,
            size : new google.maps.Size(67, 87),
            scaledSize: new google.maps.Size(40, 50)
        },
        data : {id : $marker.data('id')}
    });

    // Append to reference for later use.
    map.markers.push( marker );

    // Create info window.
    var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
    });

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, 'click', function(e) {
        map.panTo(marker.getPosition());
        openPopup(marker.data.id);
    });
}

function openPopup(id){
    $(`.c-map_infowindows`).show();
    $(`.c-map_infowindow[data-id=${id}]`)[0].parentNode.classList.remove('c-map_infoWindows_close');
    $(`.c-map_infowindow`).hide();
    $(`.c-map_infowindow[data-id=${id}]`).show();
}

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
function centerMap( map ) {

    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function( marker ){
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });

    // Case: Single marker.
    if( map.markers.length == 1 ){
        map.setCenter( bounds.getCenter() );

    // Case: Multiple markers.
    } else{
        map.fitBounds( bounds );
    }
}
